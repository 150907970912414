function getUrlParams(url) {
  // Get query string from the URL or window location
  let queryString = url ? url.split('?')[1] : window.location.search.slice(1);
  if (!queryString) {
    return false;
  }

  let obj = {};

  // Remove anything after `#`
  queryString = queryString.split('#')[0];

  let arr = queryString.split('&');

  for (const element of arr) {
    let a = element.split('=');
    let paramNum;
    let paramName = a[0].replace(/\[\d*\]/, (v) => {
      paramNum = v.slice(1, -1);
      return '';
    });

    // Ensure `paramName` is not a prototype pollution vector
    if (paramName === '__proto__' || paramName === 'constructor' || paramName === 'prototype') {
      continue; // Skip this parameter to prevent security risks
    }

    let paramValue = typeof a[1] === 'undefined' ? true : a[1];

    // Handle arrays in query params
    if (obj[paramName]) {
      if (typeof obj[paramName] === 'string') {
        obj[paramName] = [obj[paramName]];
      }
      if (typeof paramNum === 'undefined') {
        obj[paramName].push(paramValue);
      } else {
        obj[paramName][paramNum] = paramValue;
      }
    } else {
      obj[paramName] = paramValue;
    }
  }

  return obj;
}

export default getUrlParams;
