<template>
  <div
    v-if="!datamenu.tddHideSubNav"
    id="subnav"
    class="subnav-placeholder"
  >
    <nav class="subnav subnav-control subnav-tourPage">
      <div class="subnav-container is-flex">
        <div class="subnav-scroll-btn scroll-left hidden">
          <i class="fas fa-lg fa-chevron-left" />
        </div>

        <div class="subnav-wrapper">
          <button
            class="subnav-selected"
            :aria-label="datamenu.brandName"
          >
            <p class="subnav-selected-value" />
            <span class="subnav-toggle-indicator">
              <i class="fas fa-chevron-down is-closed" />
              <i class="fas fa-chevron-up is-open" />
            </span>
          </button>
          <a
            v-if="!datamenu.tddHideOverview"
            href="#overview-section"
            data-type="general interaction"
            data-name="in-page-menu"
            data-target="overview-section"
            class="subnav__link"
          >{{ datamenu.overViewTitle }}</a>

          <a
            v-if="!datamenu.tddHideItinery && datamenu.isDefaultMapImage"
            href="#app-mapModal"
            data-target="app-mapModal"
            data-name="in-page-menu"
            class="subnav__link"
          >{{ datamenu.mapTitle }}</a>
          <a
            v-if="datamenu.isUWTour && !datamenu.tddHideItinery && datamenu.directionsAny"
            href="#itinerary-section"
            data-type="general interaction"
            data-name="in-page-menu"
            data-target="itinerary-section"
            class="subnav__link"
          >{{ datamenu.itineraryTitle }}</a>
          <a
            v-if="datamenu.isUWTour && !datamenu.tddHideItinery && datamenu.tripHighlightsAny"
            href="#highlights-section"
            data-type="general interaction"
            data-name="in-page-menu"
            data-target="highlights-section"
            class="subnav__link"
          >{{ datamenu.highlightsTitle }}</a>

          <a
            v-if="!datamenu.isUWTour && !datamenu.tddHideItinery && datamenu.itineraryAny"
            href="#itinerary-section"
            data-type="general interaction"
            data-name="in-page-menu"
            data-target="itinerary-section"
            class="subnav__link"
          >{{ datamenu.itineraryTitle }}</a>

          <a
            v-if="datamenu.isC1Tour || datamenu.isV4Tour"
            href="#trip-options-section"
            data-target="trip-options-section"
            class="subnav__link"
          >{{ datamenu.tourOptionsConfiguratorTitle }}</a>

          <a
            v-if="!datamenu.isUWTour && !datamenu.tddHideItinery && datamenu.tripHighlightsAny"
            href="#highlights-section"
            data-type="general interaction"
            data-name="in-page-menu"
            data-target="highlights-section"
            class="subnav__link"
          >{{ datamenu.highlightsTitle }}</a>
          <a
            v-if="
              (!datamenu.tddHideWhatsIncluded && datamenu.whatsIncludedCol1Any) ||
                datamenu.whatsIncludedCo21Any
            "
            href="#included-section"
            data-type="general interaction"
            data-name="in-page-menu"
            data-target="included-section"
            class="subnav__link"
          >{{ datamenu.whatsIncludedTitle }}</a>
          <a
            v-if="datamenu.isUWTour && datamenu.shipsAny"
            href="#ships-section"
            data-type="general interaction"
            data-name="in-page-menu"
            data-target="ships-section"
            class="subnav__link"
          >{{ datamenu.shipsTitle }}</a>
          <div
            id="feature-subnav_cta"
            class="feature-subnav-cta sticky-nav-is-visible not-last is-hidden-mobile"
          />

          <a
            v-if="!datamenu.useWidget"
            class="subnav__link button is-secondary js-scroll-link sticky-nav-is-visible last is-hidden-mobile cta"
            :data-target="targetSection"
            :href="'#' + targetSection"
          >{{ datamenu.datesAndPricesTitle }}</a>
        </div>

        <button
          v-if="datamenu.useWidget"
          id="show-ttc-widget-button-subnav"
          class="button is-secondary sticky-nav-is-visible last cta ttc-widget-button is-hidden-desktop"
        >
          {{ datamenu.raqTitle }}
        </button>

        <div class="subnav-scroll-btn scroll-right hidden">
          <i class="fas fa-lg fa-chevron-right" />
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  props: ['datamenu'],
  data() {
    return {
      targetSection: "availability-section",
    };
  },
  mounted() {
    this.updateTargetSection();
  },
  methods: {
    updateTargetSection() {
      if (document.getElementById("tour-filters-section")) {
        this.targetSection = "tour-filters-section";
      } else {
        this.targetSection = "availability-section";
      }
    }
  }
};
</script>
